@import "../../../styles/var";

.topbar {
  background-color: #000;
  height: 65px;
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  top: -65px;
  left: 0;
  z-index: 99999;
  transition: top 0.3s ease-out;

  &.slideDown {
    top: 0;
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    margin: 0 auto;
    max-width: 650px;

    li {
      display: flex;
      align-items: center;
      min-width: 104px;
      justify-content: center;

      a {
        color: #fff;
        font-size: 13px;
        text-transform: uppercase;
        font-weight: 300;
        text-decoration: none;
        padding: 0 1rem;
        transition: all 0.3s ease-out;

        &:hover {
          color: $secondary-color;
        }
      }

      img {
        max-height: 45px;
        cursor: pointer;
      }
    }
  }

  @media (max-width: 768px) {
    .mobileBar {
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        max-height: 45px;
        position: relative;
        bottom: -4px;
      }

      .menuIcon {
        color: $secondary-color;
        cursor: pointer;
        position: relative;
        bottom: -3px;
      }
    }
  }
}
