@import "../../../styles/var";

.Sections {
  background: url("../../../assets/images/svg/pattern.svg") $primary-color;

  .section {
    display: grid;
    grid-template-columns: repeat(2, 50%);

    .image {
      max-width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
    }

    .content {
      padding: 4vw;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h1 {
        font-family: $secondary-font;
        color: $secondary-color;
        font-size: 38px;
        line-height: 40px;
        font-weight: 500;
        margin-bottom: 25px;
        display: block;
        max-width: 500px;
        margin-right: auto;
        margin-left: auto;
      }

      p {
        color: #fff;
        font-size: 14px;
        font-family: $primary-font;
        font-weight: 500;
        max-width: 500px;
        margin-right: auto;
        margin-left: auto;
      }
    }
  }

  @media (max-width: 768px) {
    .section {
      grid-template-columns: 100%;

      .image {
        height: 300px;
      }

      &:nth-of-type(even) {
        .image {
          order: 2;
        }

        .content {
          order: 1;
        }
      }
    }
  }
}
