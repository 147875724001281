@import "../../../styles/var";

.about {
  width: 100%;
  height: 100vh;
  position: relative;
  background: url("../../../assets/images/svg/pattern.svg") $primary-color;
  background-position: center;
  background-size: cover;
  display: flow-root;
  overflow: hidden;

  .aboutText {
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    margin-top: 80px;
    font-weight: 500;
    font-family: "Oswald";
    font-size: 140px;
    line-height: 135px;
    position: relative;
    overflow: hidden;

    video {
      position: absolute;
      top: -40px;
      left: 50%;
      transform: translateX(-50%);
      max-width: 1000px;
      mix-blend-mode: darken;
    }
  }

  .aboutDescription {
    color: $secondary-color;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 500;
    position: relative;
    z-index: 99;
    max-width: 690px;
    text-align: center;
    margin: 50px auto 0;
  }

  .bottomSection {
    width: 100%;
    position: absolute;
    bottom: -4px;

    .plane {
      max-width: 420px;
      margin: 0 auto -105px;
      display: block;
      position: relative;
    }

    .mountains {
      width: 100%;
      position: relative;
      z-index: 99;
      bottom: -23px;
    }
  }

  @media (min-width: 1600px) {
    .aboutText {
      font-size: 160px;
      line-height: 155px;

      video {
        transform: scale(1.2) translateX(-41.5%);
      }
    }

    .aboutDescription {
      max-width: 1108px;
      font-size: 21px;
    }

    .plane {
      max-width: 679px !important;
      margin-bottom: -160px !important;
    }

    .mountains {
      transform: scale(1.3);
      left: 91px;
      bottom: 17px;
    }
  }

  @media (max-width: 768px) {
    .aboutText {
      font-size: 80px;
      line-height: 88px;
    }

    .bottomSection {
      .plane {
        margin-bottom: -50px !important;
      }

      .mountains {
        bottom: 0;
      }
    }
  }

  @media (max-width: 480px) {
    .aboutText {
      font-size: 80px;
      line-height: 80px;
      max-width: 80%;
      margin: 50px auto 0;
    }

    .aboutDescription {
      font-size: 12px;
    }

    .bottomSection {
      .plane {
        max-width: 440px;
        margin: 0 auto -60px;
        left: 50%;
        transform: translateX(-50%);
      }

      .mountains {
        width: 200%;
        margin-left: -50%;
      }
    }
  }
}
