@import "../../../styles/var";

.internationalReach {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: $primary-color;
  display: flow-root;

  .bgVideo {
    width: 100%;
    position: absolute;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .wrapper {
    position: relative;
    z-index: 99;
  }

  .title {
    text-align: center;
    font-size: 37px;
    color: #fff;
    margin-top: 120px;
    font-weight: 400;
    line-height: 42px;
    font-family: $secondary-font;
  }

  .text {
    text-align: center;
    font-size: 15px;
    color: $secondary-color;
    font-family: $primary-font;
    font-weight: 500;
    max-width: 530px;
    margin: 20px auto 0;
  }

  .locations {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    margin: 20px auto 0;
    gap: 10px;
    max-width: min-content;

    a {
      font-size: 15px;
      font-weight: 400;
      text-decoration: none;
      color: #fff;
      font-family: $secondary-font;
      text-align: center;
      display: block;
    }
  }

  @media (max-width: 414px) {
    .locations {
      grid-template-columns: repeat(4, 1fr) !important;
    }
  }

  @media (min-width: 1600px) {
    .wrapper {
      transform: scale(1.3);

      .title {
        margin-top: 170px;
      }
    }
  }
}
