.ClientChart {
  background: url("../../../assets/images/svg/pattern.svg") #fff;
  padding: 80px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
  }
}
