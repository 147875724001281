@import "../../../styles/var";

.footer {
  height: 170px;
  background: url("../../../assets/images/svg/pattern.svg") $primary-color;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 999;

  .content {
    display: flex;
    align-items: center;
    gap: 15px;

    .title {
      color: #fff;
      font-family: $secondary-font;
      font-size: 30px;
      font-weight: 400;
    }

    .contact {
      color: $secondary-color;
      font-family: $primary-font;
      font-weight: 500;

      a {
        text-decoration: none;
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      gap: 0;
      text-align: center;

      .contact {
        margin-top: 15px;
      }
    }
  }
}
