@import "../../../styles/var";

.menuWrapper {
  position: fixed;

  .mobileMenu {
    position: fixed;
    width: 220px;
    height: calc(100vh - 65px);
    top: 65px;
    right: -220px;
    background-color: $secondary-color;
    transition: all 0.4s ease-out;

    &.opemMenu {
      right: 0;
    }

    .nav {
      display: grid;
      padding: 18px;

      a {
        font-family: $primary-font;
        color: $primary-color;
        margin-bottom: 15px;
        font-weight: 600;
      }
    }
  }

  .menuOverlay {
    position: fixed;
    height: calc(100vh - 65px);
    width: calc(100vw - 220px);
    top: 65px;
    left: calc(-1 * (100vw - 220px));
    background-color: rgba(0, 0, 0, 0.7);
    transition: all 0.4s ease-out;

    &.opemMenu {
      left: 0;
    }
  }
}
