@import "../../styles/var";

.ContactUs {
  display: block;
  padding-top: 65px;

  .branchesBanner {
    // background: url("../../assets/images/jpg/branches-banner.jpg");
    background-color: #080808;
    padding: 70px 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    section {
      max-width: 600px;
      margin: 0 auto;
      text-align: center;

      h1 {
        text-align: center;
        color: $secondary-color;
        font-size: 55px;
        font-family: $secondary-font;
        font-weight: 500;
        line-height: 55px;
        text-transform: uppercase;
      }

      p {
        text-align: center;
        font-size: 16px;
        color: #fff;
        font-family: $primary-font;
        font-weight: 500;
        max-width: 530px;
        margin: 30px auto 0;
      }

      .locations {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        margin: 20px auto 0;
        gap: 10px;
        max-width: min-content;

        span {
          font-size: 22px;
          font-weight: 400;
          text-decoration: none;
          color: $secondary-color;
          font-family: $secondary-font;
          text-align: center;
          display: block;
        }
      }

      div {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;

        a {
          color: #fff;
          text-decoration: none;
          font-weight: 100;
        }
      }
    }

    .countries {
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      .title {
        color: #fff;
        font-family: $secondary-font;
        font-size: 35px;
        line-height: 38px;
        font-weight: 400;
        text-transform: uppercase;
        padding: 30px;
      }

      .country {
        border-color: #231f20;
        border-style: solid;
        padding: 30px;
        border-right-width: 0;
        border-bottom-width: 0;
        border-left-width: 1px;
        border-top-width: 1px;

        &.selected {
          background: #454545;
        }

        h1 {
          color: #fff;
          font-family: $secondary-font;
          font-size: 22px;
          line-height: 22px;
          font-weight: 500;
          text-transform: uppercase;
          margin-bottom: 10px;
        }

        .contact {
          color: $secondary-color;
          font-family: $primary-font;
          font-weight: 500;

          a {
            text-decoration: none;
            text-transform: lowercase;
          }
        }

        &:nth-child(2),
        &:nth-child(3) {
          border-top-width: 0;
        }

        &:nth-child(3n + 1) {
          border-left-width: 0;
        }
      }

      @media (max-width: 992px) {
        grid-template-columns: repeat(2, 1fr);

        .country {
          &:nth-child(3) {
            border-top-width: 1px;
          }

          &:nth-child(2n) {
            border-left-width: 1px;
          }

          &:nth-child(2n + 1) {
            border-left-width: 0;
          }
        }
      }

      @media (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);

        .country {
          border-left-width: 0 !important;
        }
      }
    }
  }

  .slogan {
    background: #080808;

    img {
      position: relative;
      bottom: -7px;
      width: 100%;
    }
  }

  .contactForm {
    background: url("../../assets/images/svg/pattern.svg") #fff;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 35px;
  }
}
