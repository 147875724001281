@import "../../../styles/animation";
@import "../../../styles/var";

.ourSolutionsBanner {
  height: 480px;
  position: relative;
  display: flex;
  align-items: center;
  background: url("../../../assets/images/svg/pattern.svg") #fff;
  overflow: hidden;

  .backgroundText {
    position: absolute;
    bottom: 0;

    h1 {
      font-size: 15vw;
      font-family: $secondary-font;
      font-weight: 400;
      line-height: 15vw;
      color: #eff0f0;
      white-space: nowrap;
      display: inline-block;
      @include marqueeAnimation(0);
    }
  }

  .row {
    position: relative;
    z-index: 1;
    text-align: center;
  }

  .head {
    h2 {
      font-size: 35px;
      font-family: $secondary-font;
      font-weight: 400;
      line-height: 35px;
      color: $primary-color;
      margin-bottom: 10px;
    }

    span {
      font-size: 65px;
      font-family: $secondary-font;
      font-weight: 400;
      color: $secondary-color;
      line-height: 65px;
      margin-bottom: 25px;
      display: block;
    }
  }

  .content {
    .firstParagraph {
      max-width: 85%;
      margin: 0 auto;

      p {
        margin: 0;
        font-size: 20px;
        font-family: $primary-font;
        font-weight: 500;
        color: $primary-color;
        line-height: 23px;
      }
    }
  }

  @media (max-width: 768px) {
    height: auto !important;
    padding: 60px 0;

    .row {
      grid-template-columns: 100% !important;
    }

    .head {
      margin-bottom: 40px;

      h2 {
        font-size: 25px;
        line-height: 25px;
      }

      span {
        font-size: 40px;
        line-height: 40px;
      }
    }

    .firstParagraph {
      p {
        font-size: 16px !important;
      }
    }
  }
}
