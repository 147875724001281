@import "../../../styles/var";

.BannerTwo {
  background: url("../../../assets/images/jpg/ConsultingCoverTwo.jpg")
    $primary-color;
  background-position-y: -19vw;
  background-size: cover;

  .quote {
    p {
      color: #fff;
      font-size: 45px;
      font-weight: 500;
      font-family: $secondary-font;
      line-height: 45px;
      text-align: center;
      margin: 0;
      padding: 70px 0;
    }
  }
}
