@import "./var";

// Tags
body {
  margin: 0;
  font-family: $primary-font;
  font-weight: 200;

  &::-webkit-scrollbar-track {
    width: 8px;
    height: 100%;
    background-color: #d1d1d1;
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 100%;
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    height: 8px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $primary-font;
  font-weight: 300;
  margin: 0;
}

a {
  color: $secondary-color;
  font-weight: 600;
  text-transform: uppercase;
}

// CSS Vars
:root {
  --primary-color: #000000;
  --primary-dimmed-color: "#EFF0F0";
  --secondary-color: #c2986c;
}

// Container
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
}

@media (min-width: 576px) {
  .container {
    width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1140px;
  }
}

// Scroll Container
.scroll-container {
  width: 100%;
  height: 100vh;
}

.page-part {
  position: relative;
  z-index: 999;
  max-height: 100vh !important;
  overflow: hidden; 
}

// Hidden Classes
.hidden-md {
  @media (max-width: 768px) {
    display: none !important;
  }
}

.hidden-lg {
  @media (min-width: 768px) {
    display: none !important;
  }
}
