@import "../../../styles/animation";
@import "../../../styles/var";

.ourSolutionsBanner {
  height: 480px;
  position: relative;
  display: flex;
  align-items: center;
  background: url("../../../assets/images/svg/pattern.svg") #fff;
  overflow: hidden;

  .backgroundText {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    h1 {
      font-size: 15vw;
      font-family: $secondary-font;
      font-weight: 400;
      color: #eff0f0;
      white-space: nowrap;
      display: inline-block;
      @include marqueeAnimation(0);
    }
  }

  .row {
    display: grid;
    grid-template-columns: 33% 50%;
    column-gap: 75px;
    justify-content: center;
    position: relative;
    z-index: 1;
  }

  .head {
    h2 {
      font-size: 70px;
      font-family: $secondary-font;
      font-weight: 400;
      line-height: 70px;
      margin-bottom: 20px;
      color: $primary-color;
    }

    span {
      font-size: 35px;
      font-family: $secondary-font;
      font-weight: 400;
      color: $secondary-color;
      line-height: 35px;
    }
  }

  .content {
    .firstParagraph {
      margin-bottom: 30px;
      max-width: 95%;

      p {
        margin: 0;
        font-size: 20px;
        font-family: $primary-font;
        font-weight: 500;
        color: $primary-color;
        line-height: 23px;
      }

      @media (max-width: 414px) {
        p {
          font-size: 16px;
        }
      }
    }

    .secondParagraph {
      p {
        margin: 0;
        color: $secondary-color;
        font-size: 14px;
        font-family: $primary-font;
        font-weight: 500;
        line-height: 18px;
      }
    }

    a {
      position: relative;
      bottom: -35px;
      display: inline-block;
    }
  }

  @media (max-width: 768px) {
    height: auto !important;
    padding: 60px 0;

    .row {
      grid-template-columns: 100% !important;
    }

    .head {
      margin-bottom: 40px;
    }
  }

  @media (max-width: 992px) {
    height: 500px;

    .row {
      display: grid;
      grid-template-columns: 40% 50%;
      column-gap: 75px;
    }

    .content {
      a {
        bottom: -25px;
      }
    }
  }
}
