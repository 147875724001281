@keyframes jump {
  0% {
    bottom: -60px;
  }

  50% {
    bottom: -70px;
  }

  100% {
    bottom: -60px;
  }
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 60px, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(35px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50.93%);
  }
}

@keyframes scaleOutAndTranslate {
  from {
    transform: translate3d(0, 117px, 0) scale(1.5);
  }

  to {
    transform: translate3d(0, 0, 0) scale(1);
  }
}

@mixin jumpAnimation {
  position: relative;
  bottom: -60px;
  animation: jump 2s infinite;
}

@mixin fadeInUpAnimation($delay) {
  animation: fadeInUp 0.6s ease-out forwards;
  animation-delay: $delay;
  opacity: 0;
}

@mixin fadeInAnimation($delay) {
  animation: fadeIn 0.6s ease-out forwards;
  animation-delay: $delay;
  opacity: 0;
}

@mixin fadeInLeftAnimation($delay) {
  animation: fadeInLeft 0.5s ease-out forwards;
  animation-delay: $delay;
}

@mixin scaleOutAndTranslateAnimation($delay) {
  animation: scaleOutAndTranslate 0.6s ease-out forwards;
  animation-delay: $delay;
  transform: translate3d(0, 117px, 0) scale(1.5);
}

@mixin marqueeAnimation($delay) {
  animation: marquee 40s linear infinite;
  animation-delay: $delay;
  transform: translateX(0);
}
