@import "../../../styles/var";

.form {
  border: 2px solid #c2986c;
  background-color: #fff;
  width: 800px;
  display: block;
  padding: 60px 150px;
  box-sizing: border-box;
  max-width: 100%;

  .formHead {
    display: flex;
    align-items: top;
    justify-content: space-between;
    margin-bottom: 30px;
    gap: 20px;

    h1 {
      color: #020410;
      font-family: $secondary-font;
      font-size: 33px;
      line-height: 35px;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 0;
      letter-spacing: -1px;
    }

    p {
      color: $secondary-color;
      font-family: $primary-font;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -1px;
      font-weight: 500;
    }

    img {
      width: 65px;
      height: 65px;
    }
  }

  form {
    .formGroup {
      position: relative;
      margin-bottom: 30px;
      width: 100%;

      label {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -80px;
        text-align: right;
        width: 60px;
        color: $secondary-color;
        font-family: $primary-font;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 500;
      }

      input {
        width: -webkit-fill-available;
        padding: 10px;
        border: 2px solid $secondary-color;
      }
    }

    button {
      background-color: $secondary-color;
      color: #fff;
      font-family: $primary-font;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 500;
      padding: 10px 20px;
      border: none;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: #000;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 60px 30px;
    margin: 0 16px;

    .formHead {
      gap: 0;

      h1 {
        font-size: 28px !important;
      }

      p {
        font-size: 14px !important;
        line-height: 20px !important;
      }
    }

    form {
      .formGroup {
        label {
          position: unset;
          margin-bottom: 5px;
          transform: unset;
          display: block;
          width: unset;
          text-align: unset;
        }
      }
    }
  }
}
