@import "../../../styles/var";

.Tabs {
  background-color: #000000;
  border-bottom: 1px solid $secondary-color;

  .tab {
    border-top: 1px solid $secondary-color;

    &.openTab {
      background-image: url("../../../assets/images/svg/pattern.svg");
      padding-bottom: 50px;
    }

    .tabTitle {
      color: #fff;
      font-family: $secondary-font;
      font-weight: 300;
      text-transform: uppercase;
      font-size: 35px;
      cursor: pointer;
      padding: 25px 0;
    }

    .tabContent {
      overflow: hidden;
      max-height: 0px;
      transition: max-height 0.7s ease-out;

      &.openTab {
        transition: max-height 0.89s ease-out !important;
        max-height: 1000px;
      }

      p {
        font-size: 16px;
        color: $secondary-color;
        font-family: $primary-font;
        font-weight: 300;
      }

      .slider {
        margin-top: 50px;
        position: relative;
        margin-bottom: 50px;

        .slide {
          &.normalSlide {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 25px;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          &.overlapSlide {
            position: relative;

            .slideImage1 {
              max-width: 85%;
            }

            .slideImage2 {
              position: absolute;
              width: auto;
              right: 0;
              max-width: 350px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }

        .arrow {
          width: 15px;
          cursor: pointer;
          position: absolute;
          top: 50%;
          z-index: 9999;
          transform: translateY(-50%);

          &.arrowRight {
            right: -60px;
          }

          &.arrowLeft {
            left: -60px;

            img {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }

  :global {
    .carousel {
      .slide {
        text-align: left !important;
      }
    }
  }

  @media (max-width: 768px) {
    .tab {
      .tabTitle {
        font-size: 25px;
      }

      .tabContent {
        p {
          font-size: 14px;
          line-height: 20px;
        }

        a {
          font-size: 14px;
        }

        .slider {
          .slide {
            &.normalSlide {
              grid-template-columns: 1fr;
            }

            &.overlapSlide {
              display: grid;
              grid-template-columns: 1fr;
              gap: 25px;

              .slideImage1 {
                max-width: 100%;
              }

              .slideImage2 {
                position: unset;
                max-width: 100%;
                transform: unset;
              }
            }
          }

          .arrow {
            top: -40px;
            transform: unset;

            &.arrowRight {
              right: 0;
            }

            &.arrowLeft {
              right: 40px;
              left: unset;

              img {
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }

    :global {
      .slider-wrapper {
        height: auto !important;
      }
    }
  }
}
