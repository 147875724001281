@import "../../../styles/var";

.Header {
  background: url("../../../assets/images/jpg/ConsultingHead.jpg");
  height: 550px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .overlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.167);
    display: flex;
    align-items: center;
    justify-content: center;

    .content {
      position: relative;

      h1 {
        text-align: center;
        color: #fff;
        font-size: 65px;
        font-family: $secondary-font;
        font-weight: 500;
        line-height: 65px;
      }

      .image {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -100px;

        img {
          width: 65px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .overlay {
      .content {
        h1 {
          font-size: 55px;
          line-height: 55px;
        }
      }
    }
  }
  
  @media (max-width: 414px) {
    .overlay {
      .content {
        h1 {
          font-size: 45px;
          line-height: 45px;
        }
      }
    }
  }
}
