@import "../../../styles/animation";
@import "../../../styles/var";

.ourSolutions {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  background: url("../../../assets/images/svg/pattern.svg") $primary-color;

  .solution {
    width: 100%;
    height: 300px;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    box-sizing: border-box;
    background-repeat: no-repeat;

    .overlay {
      padding: 25px;
      background-color: #1a1a1ac5;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: flex-end;
      opacity: 0;
      transition: all 0.25s ease-out;

      .content {
        span {
          color: $secondary-color;
          font-family: $primary-font;
          font-weight: 600;
          text-decoration: underline;
          font-size: 19px;
          margin-bottom: 5px;
          display: block;
          opacity: 0;
        }

        h3 {
          font-size: 30px;
          color: #fff;
          font-family: $secondary-font;
          font-weight: 400;
          line-height: 34px;
          margin-top: 5px;
          opacity: 0;
        }
      }

      &:hover {
        opacity: 1;

        .content {
          span {
            @include fadeInLeftAnimation(0s);
          }
          h3 {
            @include fadeInLeftAnimation(0.2s);
          }
        }
      }
    }
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(2, calc(100% / 2));

    .solution {
      height: 150px;

      .overlay {
        .content {
          span {
            font-size: 14px;
          }

          h3 {
            font-size: 20px;
            line-height: 20px;
          }
        }
      }
    }
  }

  @media (min-width: 500px) and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 768px) and (max-width: 992px) {
    grid-template-columns: repeat(4, calc(100% / 4));

    .solution {
      height: 220px;

      .overlay {
        .content {
          span {
            font-size: 16px;
          }

          h3 {
            font-size: 23px;
            line-height: 23px;
          }
        }
      }
    }
  }

  @media (min-width: 992px) and (max-width: 1200px) {
    grid-template-columns: repeat(5, calc(100% / 5));
    .solution {
      height: 250px
    }
  }
}
