@import "../../../styles/var";

.moreInfo {
  width: 100%;
  height: 100vh;
  position: relative;
  background: url("../../../assets/images/jpg/more-info.jpg") $primary-color;
  background-position: center;
  background-size: cover;
  display: flow-root;
  overflow: hidden;

  .content {
    position: relative;
    z-index: 99;
    max-width: 800px;
    margin: 0 auto;
  }

  .head {
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 36px;
    line-height: 40px;
    position: relative;
    z-index: 99;
    max-width: 80%;
    margin: 130px auto 0;
  }

  .slogan {
    margin-top: 40px;
    font-size: 20px;
    color: #fff;
    font-family: $primary-font;
    font-weight: 500;
    text-align: center;
    line-height: 26px;
    position: relative;
    z-index: 99;
  }

  .paragraph {
    font-size: 16px;
    color: $secondary-color;
    text-align: center;
    font-weight: 500;
    line-height: 20px;
    margin: 40px auto 0;
    max-width: 755px;
    position: relative;
    z-index: 99;
  }

  .blackBg {
    width: 90%;
    max-width: 1045px;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    height: calc(100vh - 184px);

    :global {
      .react-reveal {
        height: 100%;
        width: 100%;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media (min-width: 1600px) {
    .head {
      font-size: 50px;
      line-height: 55px;
    }

    .slogan {
      font-size: 24px;
      line-height: 29px;
    }

    .paragraph {
      font-size: 20px;
      line-height: 24px;
      max-width: 1100px;
    }

    .blackBg {
      max-width: 1280px;
      height: calc(100vh - 210px);
    }
  }

  @media (max-width: 768px) {
    .head {
      margin-top: 100px;
      font-size: 28px;
      line-height: 30px;
      padding: 0 15px;

      div {
        margin-bottom: 10px;
      }
    }

    .slogan {
      font-size: 15px;
      line-height: 20px;
      padding: 0 15px;
    }

    .paragraph {
      font-size: 14px;
      line-height: 20px;
      padding: 0 15px;
    }

    .blackBg {
      :global {
        .react-reveal {
          height: 100%;
        }
      }

      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  @media (max-width: 414px) {
    .head {
      margin-top: 115px;
      font-size: 21px;
    }

    .slogan {
      font-size: 13px;
    }

    .paragraph {
      font-size: 11px;
      line-height: 17px;
    }
  }
}
