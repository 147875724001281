@import "../../styles/var";

.MobileDetection {
  .header {
    background: url("../../assets/images/svg/pattern.svg") $primary-color;
    height: 950px;
    display: flow-root;

    .text {
      margin-top: 170px;
      text-align: center;

      span {
        color: #fff;
        font-size: 34px;
        font-weight: 500;
        font-family: $primary-font;
      }

      h1 {
        color: $secondary-color;
        font-size: 60px;
        font-family: $secondary-font;
        font-weight: 400;
        line-height: 60px;
      }

      p {
        color: #fff;
        font-size: 20px;
        font-weight: 500;
        font-family: $primary-font;
        max-width: 800px;
        margin: 25px auto 0;
      }
    }

    img {
      margin-top: 60px;
      max-width: 100%;
    }
  }

  .downloadBrochure {
    background: url("../../assets/images/svg/pattern.svg") #fff;
    padding-top: 260px;
    padding-bottom: 80px;

    .row {
      display: grid;
      grid-template-columns: 40% 60%;
      gap: 20px;

      .image {
        img {
          max-width: 100%;
        }
      }

      .text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .phrase {
          color: $secondary-color;
          font-size: 35px;
          line-height: 37px;
          margin-top: 0;
          font-family: $secondary-font;
          font-weight: 500;
        }

        a {
          font-family: $primary-font;
          text-decoration: none;
          color: $primary-color;
          font-weight: 500;
          font-size: 16px;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .downloadBrochure {
      padding-top: 150px;
    }
  }

  @media (max-width: 992px) {
    .downloadBrochure {
      padding-top: 50px;

      .row {
        grid-template-columns: repeat(2, 1fr);

        .text {
          .phrase {
            font-size: 30px;
            line-height: 32px;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .header {
      height: auto;
      padding-bottom: 50px;
    }

    .downloadBrochure {
      .row {
        grid-template-columns: 1fr;

        .text {
          .phrase {
            margin-bottom: 25px;

            br {
              display: none;
            }
          }
        }
      }
    }
  }

  @media (max-width: 414px) {
    .header {
      .text {
        span {
          font-size: 24px;
        }

        h1 {
          margin-top: 5px;
          font-size: 45px;
          line-height: 45px;
        }

        p {
          font-size: 16px;
        }
      }
    }
  }
}
