@import "../../../styles/var";

.BannerOne {
  background: url("../../../assets/images/svg/pattern.svg") $primary-color;

  .quote {
    p {
      color: #fff;
      font-size: 45px;
      font-weight: 500;
      font-family: $secondary-font;
      line-height: 45px;
      text-align: center;
      margin: 0;
      padding: 50px 0;
    }
  }

  .cover {
    background: url("../../../assets/images/jpg/ConsultingCoverOne.jpg") #7c7c7c;
    height: 250px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: -15vw;
  }

  .content {
    display: flow-root;
    padding: 80px 0;

    .p {
      margin-top: 0;
      color: $secondary-color;
      font-family: $primary-font;
      font-weight: 500;
      margin-bottom: 30px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 768px) {
    .quote {
      p {
        font-size: 30px;
        line-height: 35px;
        padding: 50px 15px;
      }
    }

    .cover {
      background-position-y: center;
      background-position-x: center;
    }
  }
}
