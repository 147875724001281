@import "../../../styles/var";
@import "../../../styles/animation";

.header {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;

  .introVideo {
    width: 100%;
    position: absolute;
    z-index: -1;
    filter: grayscale(100%);
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    .content {
      text-align: center;
      transform: scale(1.1);

      .logo {
        width: 80px;
        margin-bottom: 17px;
        @include scaleOutAndTranslateAnimation(3s);
      }

      .companyName {
        font-size: 14px;
        font-family: $primary-font;
        font-weight: 600;
        color: #fff;
        margin-bottom: 15px;
        @include fadeInUpAnimation(4s);
      }

      .companySlogan {
        font-size: 44px;
        line-height: 44px;
        font-family: $secondary-font;
        font-weight: 500;
        color: #fff;
        margin-bottom: 40px;
        @include fadeInUpAnimation(4.3s);
      }

      .explore {
        font-size: 14px;
        display: inline-block;
        @include fadeInUpAnimation(5s);
      }

      .scrollDownContainer {
        @include fadeInAnimation(7s);

        .scrollDown {
          color: $secondary-color;
          cursor: pointer;
          @include jumpAnimation;
        }
      }

      @media (min-width: 1600px) {
        transform: scale(1.4);
      }

      @media (max-width: 500px) {
        transform: scale(0.87);
      }
    }
  }
}
