// Manrope
@font-face {
  font-family: "Manrope";
  src: url("../assets/fonts/Manrope/Manrope-Light.ttf");
  font-weight: 100;
}

@font-face {
  font-family: "Manrope";
  src: url("../assets/fonts/Manrope/Manrope-Regular.ttf");
  font-weight: 200;
}

@font-face {
  font-family: "Manrope";
  src: url("../assets/fonts/Manrope/Manrope-Medium.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Manrope";
  src: url("../assets/fonts/Manrope/Manrope-SemiBold.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Manrope";
  src: url("../assets/fonts/Manrope/Manrope-Bold.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Manrope";
  src: url("../assets/fonts/Manrope/Manrope-ExtraBold.ttf");
  font-weight: 600;
}

// Oswald
@font-face {
  font-family: "Oswald";
  src: url("../assets/fonts/Oswald/Oswald-Light.ttf");
  font-weight: 100;
}

@font-face {
  font-family: "Oswald";
  src: url("../assets/fonts/Oswald/Oswald-Regular.ttf");
  font-weight: 200;
}

@font-face {
  font-family: "Oswald";
  src: url("../assets/fonts/Oswald/Oswald-Medium.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Oswald";
  src: url("../assets/fonts/Oswald/Oswald-SemiBold.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Oswald";
  src: url("../assets/fonts/Oswald/Oswald-Bold.ttf");
  font-weight: 500;
}
