@import "../../../styles/var";

.Banner {
  height: 430px;
  background-image: url(../../../assets/images/jpg/SolutionsBanner.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .overlay {
    height: 100%;
    background-color: #2121219d;
    display: flex;
    align-items: center;

    p {
      color: #fff;
      font-size: 24px;
      font-family: $primary-font;
      font-weight: 300;
      text-transform: uppercase;
      max-width: 900px;
    }
  }

  @media (max-width: 768px) {
    height: auto;

    .overlay {
      padding: 30px 0;
      p {
        font-size: 18px;
      }
    }
  }
}
